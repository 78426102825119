import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui';
import './plugins/elememt'
import './plugins/vant'
import 'element-ui/lib/theme-chalk/index.css';
import eleLocale from 'element-ui/lib/locale';
// 全局css默认样式
import './assets/css/global.css'
// 国际化
import i18n from './lang/index'

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import 'leaflet/dist/leaflet.css'
// Google Analytics
import VueGtag from "vue-gtag"
import './assets/css/font.css'

// 添加Fastclick移除移动端点击延迟
import FastClick from 'fastclick'
//FastClick的ios点击穿透解决方案
FastClick.prototype.focus = function(targetElement) {
    let length;
    if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
        length = targetElement.value.length;
        targetElement.focus();
        targetElement.setSelectionRange(length, length);
    } else {
        targetElement.focus();
    }
};

router.afterEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});

FastClick.attach(document.body)

Vue.use(VueGtag, {
    config: {
        id: "UA-192948846-1",
        params: {
            send_page_view: true
        }
    }
}, router)

Vue.config.productionTip = false

Vue.config.lang = 'ja'
eleLocale.i18n((key, value) => i18n.t(key, value))


// 分转换为元过滤器
Vue.filter('formataPrice', function(val) {
    return (val / 100).toFixed(2)
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')