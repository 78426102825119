import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        redirect: { name: 'index-home' }, // 预定面
        component: () =>
            import ('@/layout/home.vue'),
        children: [{
                path: '/index',
                name: 'index',
                component: () =>
                    import ('@/views/home/index')
            },
            {
                path: '/department',
                name: 'department',
                component: () =>
                    import ('@/views/home/department')
            },
            {
                path: '/home',
                name: 'index-home',
                component: () =>
                    import ('@/views/home/index-home')
            },
            {
                path: '/list',
                name: 'list',
                redirect: { name: 'index' },
            },
            {
                path: '/recommend',
                name: 'recommend',
                component: () =>
                    import ('@/views/recommend/index'),
            },
            {
                path: '/recommend2',
                name: 'recommend2',
                component: () =>
                    import ('@/views/recommend/index5'),
            },
            {
                path: '/special',
                name: 'special',
                component: () =>
                    import ('@/views/recommend/index2'),
            },
            {
                path: '/gototravel',
                name: 'gototravel',
                component: () =>
                    import ('@/views/recommend/index3'),
            },
            {
                path: '/koreanfood',
                name: 'koreanfood',
                component: () =>
                    import ('@/views/recommend/index6'),
            },
            {
                path: '/detail',
                name: 'detail',
                component: () =>
                    import ('@/views/detail/index')
            },
            {
                path: '/departmentDetail',
                name: 'departmentDetail',
                component: () =>
                    import ('@/views/detail/department')
            },
            {
                path: '/nearby',
                name: 'nearby',
                component: () =>
                    import ('@/views/nearby/index')
            },
            // {
            //   path: '/test',
            //   name: 'test',
            //   component: () => import('@/views/test/index')
            // },
            {
                path: '/chinaMap',
                name: 'chinaMap',
                component: () =>
                    import ('@/views/test/chinaMap')
            },
            {
                path: '/chinaMap2',
                name: 'chinaMap2',
                component: () =>
                    import ('@/views/test/chinaMap2')
            },

            {
                path: '/filmcontest',
                name: 'filmcontest',
                component: () =>
                    import ('@/views/filmcontest/index')
            },
            {
                path: '/promotion',
                name: 'promotion',
                component: () =>
                    import ('@/views/chat/index')
            },

        ],
    },
    {
        path: '/1stanniv',
        name: '1stanniv',
        component: () =>
            import ('@/views/1stanniv/index')
    },
    {
        path: '/2ndanniv',
        name: '2ndanniv',
        component: () =>
            import ('@/views/2ndanniv/index')
    },
    {
        path: '/sushirosgcp',
        name: 'sushirosgcp',
        component: () =>
            import ('@/views/sushirosgcp/index')
    },
    {
        path: '/cp240201-01',
        name: 'cp240201-01',
        component: () =>
            import ('@/views/cp240201-01/index')
    },
    {
        path: '/cp240201-02',
        name: 'cp240201-02',
        component: () =>
            import ('@/views/cp240201-02/index')
    },
    {
        path: '/cp240201-03',
        name: 'cp240201-03',
        component: () =>
            import ('@/views/cp240201-03/index')
    },
    {
        path: '/cp240201-04',
        name: 'cp240201-04',
        component: () =>
            import ('@/views/cp240201-04/index')
    },
    {
        path: '/cp240301-01',
        name: 'cp240301-01',
        component: () =>
            import ('@/views/cp240301-01/index')
    },
    {
        path: '/cp240301-01-t&c',
        name: 'cp240301-01-t&c',
        component: () =>
            import ('@/views/cp240301-01-t&c/index')
    },
    {
        path: '/aboutus',
        name: 'aboutus',
        component: () =>
            import ('@/views/aboutus/index')
    },
    {
        path: '/news',
        name: 'news',
        component: () =>
            import ('@/views/news/index')
    },
    {
        path: '/news-20220420',
        name: 'news-20220420',
        component: () =>
            import ('@/views/news/news')
    },
    {
        path: '/tcapp-download-a',
        name: 'tcapp-download-a',
        component: () =>
            import ('@/views/appDownloadPage/index')
    },

    {
        path: '/ver',
        name: 'ver',
        component: () =>
            import ('@/views/ver/index')
    },
    {
        path: '/chat',
        name: 'chat',
        component: () =>
            import ('@/views/chat/index2')
    },
    {
        path: '/tcapp-letsgo-ko',
        name: 'customer',
        component: () =>
            import ('@/views_mb/customer/index')
    },
    {
        path: '/tcapp-letsgo-zh',
        name: 'customer2',
        component: () =>
            import ('@/views_mb/customer/index2')
    },
    {
        path: '/tcapp-letsgo-tw',
        name: 'customer3',
        component: () =>
            import ('@/views_mb/customer/index3')
    },
    {
        path: '/tcapp-letsgo-en',
        name: 'customer4',
        component: () =>
            import ('@/views_mb/customer/index4')
    },
    {
        path: '/tcapp-letsgo-jp',
        name: 'customer5',
        component: () =>
            import ('@/views_mb/customer/index5')
    },
    {
        path: '/tcapp-letsgo-SC',
        name: 'tcapp1',
        component: () =>
            import ('@/views_pc/customer/index')
    },
    {
        path: '/tcapp-letsgo-KR',
        name: 'tcapp2',
        component: () =>
            import ('@/views_pc/customer/index2')
    },
    {
        path: '/tcapp-letsgo-JA',
        name: 'tcapp3',
        component: () =>
            import ('@/views_pc/customer/index3')
    },
    {
        path: '/tcapp-letsgo-ENG',
        name: 'tcapp4',
        component: () =>
            import ('@/views_pc/customer/index4')
    },
    {
        path: '/tcapp-letsgo-TC',
        name: 'tcapp5',
        component: () =>
            import ('@/views_pc/customer/index5')
    },
    {
        path: '/tcapp-japanshopping',
        name: 'tcapp6',
        component: () =>
            import ('@/views/japanshopping/index')
    },
    {
        path: '/tcapp-gojapanshopping',
        name: 'tcapp7',
        component: () =>
            import ('@/views/japanshopping/index2')
    },
    {
        path: '/tcapp-japanticket',
        name: 'japanticket',
        component: () =>
            import ('@/views/japanshopping/index3')
    },
    {
        path: '/hfPage',
        name: 'hfPage',
        component: () =>
            import ('@/views_mb/hfPage/index'),
    },
    {
        path: '/hfPage_nav',
        name: 'hfPage_nav',
        component: () =>
            import ('@/views_mb/hfPage/newIndex_nav'),
    },
    {
        path: '/hfPage_home',
        name: 'hfPage_home',
        component: () =>
            import ('@/views_mb/hfPage/newIndex_home'),
    },
    {
        path: '/HowtoUse',
        name: 'HowtoUse',
        component: () =>
            import ('@/views_mb/hfPage/HowtoUse'),
    },
    {
        path: '/TravelTips',
        name: 'TravelTips',
        component: () =>
            import ('@/views_mb/hfPage/TravelTips'),
    },
    {
        path: '/TravelTips_detail',
        name: 'TravelTips_detail',
        component: () =>
            import ('@/views_mb/hfPage/TravelTips_detail'),
    },
    {
        path: '/ReferenceLink',
        name: 'ReferenceLink',
        component: () =>
            import ('@/views_mb/hfPage/ReferenceLink'),
    },
    {
        path: '/hfPage_list',
        name: 'hfPage_list',
        component: () =>
            import ('@/views_mb/hfPage/newIndex_list'),
    },
    {
        path: '/Filter',
        name: 'Filter',
        component: () =>
            import ('@/views_mb/hfPage/Filter'),
    },
    {
        path: '/hfkansai',
        name: 'hfkansai',
        redirect: 'hfkansai/sc',
        component: () =>
            import ('@/views/hfkansai/index'),
        children: [{
                path: 'sc',
                name: 'hfkansaiScPage',
                component: () =>
                    import ('@/views_mb/hfkansai/zh')
            }, {
                path: 'tc',
                name: 'hfkansaiTcPage',
                component: () =>
                    import ('@/views_mb/hfkansai/zh_tw')
            }, {
                path: 'en',
                name: 'hfkansaiEnPage',
                component: () =>
                    import ('@/views_mb/hfkansai/en')
            }, {
                path: 'kr',
                name: 'hfkansaiKrPage',
                component: () =>
                    import ('@/views_mb/hfkansai/kr')
            },
            {
                path: 'jp',
                name: 'hfkansaiJaPage',
                component: () =>
                    import ('@/views_mb/hfkansai/ja')
            }
        ]
    },
    {
        path: '/hftokyo',
        name: 'hftokyo',
        redirect: 'hftokyo/sc',
        component: () =>
            import ('@/views/hftokyo/index'),
        children: [{
                path: 'sc',
                name: 'hftokyoScPage',
                component: () =>
                    import ('@/views_mb/hftokyo/zh')
            }, {
                path: 'tc',
                name: 'hftokyoTcPage',
                component: () =>
                    import ('@/views_mb/hftokyo/zh_tw')
            }, {
                path: 'en',
                name: 'hftokyoEnPage',
                component: () =>
                    import ('@/views_mb/hftokyo/en')
            }, {
                path: 'kr',
                name: 'hftokyoKrPage',
                component: () =>
                    import ('@/views_mb/hftokyo/kr')
            }
        ]
    },
    {
        path: '/hffukuoka',
        name: 'hffukuoka',
        redirect: 'hffukuoka/sc',
        component: () =>
            import ('@/views/hffukuoka/index'),
        children: [{
                path: 'sc',
                name: 'hffukuokaScPage',
                component: () =>
                    import ('@/views_mb/hffukuoka/zh')
            }, {
                path: 'tc',
                name: 'hffukuokaTcPage',
                component: () =>
                    import ('@/views_mb/hffukuoka/zh_tw')
            }, {
                path: 'en',
                name: 'hffukuokaEnPage',
                component: () =>
                    import ('@/views_mb/hffukuoka/en')
            }, {
                path: 'kr',
                name: 'hffukuokaKrPage',
                component: () =>
                    import ('@/views_mb/hffukuoka/kr')
            }
        ]
    },
    {
        path: '/hfokayama',
        name: 'hfokayama',
        redirect: 'hfokayama/sc',
        component: () =>
            import ('@/views/hfokayama/index'),
        children: [{
                path: 'sc',
                name: 'hfokayamaScPage',
                component: () =>
                    import ('@/views_mb/hfokayama/zh')
            }, {
                path: 'tc',
                name: 'hfokayamaTcPage',
                component: () =>
                    import ('@/views_mb/hfokayama/zh_tw')
            }, {
                path: 'en',
                name: 'hfokayamaEnPage',
                component: () =>
                    import ('@/views_mb/hfokayama/en')
            }, {
                path: 'kr',
                name: 'hfokayamaKrPage',
                component: () =>
                    import ('@/views_mb/hfokayama/kr')
            }
            , {
                path: 'jp',
                name: 'hfokayamaJpPage',
                component: () =>
                    import ('@/views_mb/hfokayama/jp')
            }
        ]
    },
    {
        path: '/hfokinawa',
        name: 'hfokinawa',
        redirect: 'hfokinawa/sc',
        component: () =>
            import ('@/views/hfokinawa/index'),
        children: [{
                path: 'sc',
                name: 'hfokinawaScPage',
                component: () =>
                    import ('@/views_mb/hfokinawa/zh')
            }, {
                path: 'tc',
                name: 'hfokinawaTcPage',
                component: () =>
                    import ('@/views_mb/hfokinawa/zh_tw')
            }, {
                path: 'en',
                name: 'hfokinawaEnPage',
                component: () =>
                    import ('@/views_mb/hfokinawa/en')
            }, {
                path: 'kr',
                name: 'hfokinawaKrPage',
                component: () =>
                    import ('@/views_mb/hfokinawa/kr')
            }
            , {
                path: 'jp',
                name: 'hfokinawaJpPage',
                component: () =>
                    import ('@/views_mb/hfokinawa/jp')
            }
        ]
    },
    {
        path: '/hfhiroshima',
        name: 'hfhiroshima',
        redirect: 'hfhiroshima/sc',
        component: () =>
            import ('@/views/hfhiroshima/index'),
        children: [{
                path: 'sc',
                name: 'hfhiroshimaScPage',
                component: () =>
                    import ('@/views_mb/hfhiroshima/zh')
            }, {
                path: 'tc',
                name: 'hfhiroshimaTcPage',
                component: () =>
                    import ('@/views_mb/hfhiroshima/zh_tw')
            }, {
                path: 'en',
                name: 'hfhiroshimaEnPage',
                component: () =>
                    import ('@/views_mb/hfhiroshima/en')
            }, {
                path: 'kr',
                name: 'hfhiroshimaKrPage',
                component: () =>
                    import ('@/views_mb/hfhiroshima/kr')
            }
            , {
                path: 'jp',
                name: 'hfhiroshimaJpPage',
                component: () =>
                    import ('@/views_mb/hfhiroshima/jp')
            }
        ]
    },
    {
        path: '/hfwakayama',
        name: 'hfwakayama',
        redirect: 'hfwakayama/sc',
        component: () =>
            import ('@/views/hfwakayama/index'),
        children: [{
                path: 'sc',
                name: 'hfwakayamaScPage',
                component: () =>
                    import ('@/views_mb/hfwakayama/zh')
            }, {
                path: 'tc',
                name: 'hfwakayamaTcPage',
                component: () =>
                    import ('@/views_mb/hfwakayama/zh_tw')
            }, {
                path: 'en',
                name: 'hfwakayamaEnPage',
                component: () =>
                    import ('@/views_mb/hfwakayama/en')
            }, {
                path: 'kr',
                name: 'hfwakayamaKrPage',
                component: () =>
                    import ('@/views_mb/hfwakayama/kr')
            }
            , {
                path: 'jp',
                name: 'hfwakayamaJpPage',
                component: () =>
                    import ('@/views_mb/hfwakayama/jp')
            }
        ]
    },
    {
        path: '/hffukui',
        name: 'hffukui',
        redirect: 'hffukui/sc',
        component: () =>
            import ('@/views/hffukui/index'),
        children: [{
                path: 'sc',
                name: 'hffukuiScPage',
                component: () =>
                    import ('@/views_mb/hffukui/zh')
            }, {
                path: 'tc',
                name: 'hffukuiTcPage',
                component: () =>
                    import ('@/views_mb/hffukui/zh_tw')
            }, {
                path: 'en',
                name: 'hffukuiEnPage',
                component: () =>
                    import ('@/views_mb/hffukui/en')
            }, {
                path: 'kr',
                name: 'hffukuiKrPage',
                component: () =>
                    import ('@/views_mb/hffukui/kr')
            }
            , {
                path: 'jp',
                name: 'hffukuiJpPage',
                component: () =>
                    import ('@/views_mb/hffukui/jp')
            }
        ]
    },
    {
        path: '/hftottori',
        name: 'hftottori',
        redirect: 'hftottori/sc',
        component: () =>
            import ('@/views/hftottori/index'),
        children: [{
                path: 'sc',
                name: 'hftottoriScPage',
                component: () =>
                    import ('@/views_mb/hftottori/zh')
            }, {
                path: 'tc',
                name: 'hftottoriTcPage',
                component: () =>
                    import ('@/views_mb/hftottori/zh_tw')
            }, {
                path: 'en',
                name: 'hftottoriEnPage',
                component: () =>
                    import ('@/views_mb/hftottori/en')
            }, {
                path: 'kr',
                name: 'hftottoriKrPage',
                component: () =>
                    import ('@/views_mb/hftottori/kr')
            }
            , {
                path: 'jp',
                name: 'hftottoriJpPage',
                component: () =>
                    import ('@/views_mb/hftottori/jp')
            }
        ]
    },
    {
        path: '/hfsaga',
        name: 'hfsaga',
        redirect: 'hfsaga/sc',
        component: () =>
            import ('@/views/hfsaga/index'),
        children: [{
                path: 'sc',
                name: 'hfsagaScPage',
                component: () =>
                    import ('@/views_mb/hfsaga/zh')
            }, {
                path: 'tc',
                name: 'hfsagaTcPage',
                component: () =>
                    import ('@/views_mb/hfsaga/zh_tw')
            }, {
                path: 'en',
                name: 'hfsagaEnPage',
                component: () =>
                    import ('@/views_mb/hfsaga/en')
            }, {
                path: 'kr',
                name: 'hfsagaKrPage',
                component: () =>
                    import ('@/views_mb/hfsaga/kr')
            }
            , {
                path: 'jp',
                name: 'hfsagaJpPage',
                component: () =>
                    import ('@/views_mb/hfsaga/jp')
            }
        ]
    },
    {
        path: '/hfhokkaido',
        name: 'hfhokkaido',
        redirect: 'hfhokkaido/sc',
        component: () =>
            import ('@/views/hfhokkaido/index'),
        children: [{
                path: 'sc',
                name: 'hfhokkaidoScPage',
                component: () =>
                    import ('@/views_mb/hfhokkaido/zh')
            }, {
                path: 'tc',
                name: 'hfhokkaidoTcPage',
                component: () =>
                    import ('@/views_mb/hfhokkaido/zh_tw')
            }, {
                path: 'en',
                name: 'hfhokkaidoEnPage',
                component: () =>
                    import ('@/views_mb/hfhokkaido/en')
            }, {
                path: 'kr',
                name: 'hfhokkaidoKrPage',
                component: () =>
                    import ('@/views_mb/hfhokkaido/kr')
            }
            , {
                path: 'jp',
                name: 'hfhokkaidoJpPage',
                component: () =>
                    import ('@/views_mb/hfhokkaido/jp')
            }
        ]
    },
    {
        path: '/exploreallpass',
        name: 'exploreallpassSc',
        component: () =>
            import ('@/views/allpass/index')
    },
    {
        path: '/exploreallpass/zh/',
        name: 'exploreallpassSc',
        component: () =>
            import ('@/views/allpass/zh')
    },
    {
        path: '/exploreallpass/zh_tw/',
        name: 'exploreallpassSc',
        component: () =>
            import ('@/views/allpass/zh_tw')
    },
    {
        path: '/exploreallpass/en/',
        name: 'exploreallpassSc',
        component: () =>
            import ('@/views/allpass/en')
    },
    {
        path: '/exploreallpass/ko/',
        name: 'exploreallpassSc',
        component: () =>
            import ('@/views/allpass/kr')
    },
    // 下载页面
    // {
    //     path: '/tcapp-download-b-copy',
    //     name: 'tcapp-download-b-copy',
    //     component: () =>
    //     import ('@/views/appDownloadPageB-copy/index')
    // },
    {
        path: '/tcapp-download-b',
        name: 'tcapp-download-b',
        component: () =>
            import ('@/views/appDownloadPageB/index')
    },
    {
        path: '/tcapp-download-b/sc',
        name: 'tcapp-download-bSc',
        component: () =>
            import ('@/views/appDownloadPageB/indexZh')
    },
    {
        path: '/tcapp-download-b/tc',
        name: 'tcapp-download-bTc',
        component: () =>
            import ('@/views/appDownloadPageB/indexTw')
    },
    {
        path: '/tcapp-download-b/en',
        name: 'tcapp-download-bEn',
        component: () =>
            import ('@/views/appDownloadPageB/indexEn')
    },
    {
        path: '/tcapp-download-b/kr',
        name: 'tcapp-download-bKr',
        component: () =>
            import ('@/views/appDownloadPageB/indexKo')
    },
]

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject)
            return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    //replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router