import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homeSearchForm: null,
    areaIds: ['100001000', '100001001', '100001009', '100001012', '100001043', '100001052'] //地址id
  },
  mutations: {
    ADD_HOMESEARCHFORM(state, val) {
      state.homeSearchForm = val
    },
    RESET_SEARCHFORM(state) {
      state.homeSearchForm = null
    }
  },
  actions: {
    setSearchForm({commit}, form) {
      console.log(form)
      commit('ADD_HOMESEARCHFORM', form)
    },
    resetSearchForm({commit}) {
      commit('RESET_SEARCHFORM')
    }
  },
  modules: {}
})
