import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementZhTwLocale from 'element-ui/lib/locale/lang/zh-TW'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementKoLocale from 'element-ui/lib/locale/lang/ko'
// import zhLocale from './zh'
// import zhTwLocale from './zh_tw'
// import enLocale from './en'
// import jaLocale from './ja'
// import koLocale from './ko'
import axios from 'axios'

const ax = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  responseType: 'json'
})

Vue.use(VueI18n)

// const messages = {
//   zh: {
//     ...zhLocale,
//     ...elementZhLocale
//   },
//   zh_tw: {
//     ...zhTwLocale,
//     ...elementZhTwLocale
//   },
//   en: {
//     ...enLocale,
//     ...elementEnLocale
//   },
//   ja: {
//     ...jaLocale,
//     ...elementJaLocale
//   },
//   ko: {
//     ...koLocale,
//     ...elementKoLocale
//   },
// }

// 系统支持的语言
const supportLans = [
  {
    value: 'zh',
    label: '简体中文'
  },
  {
    value: 'zh_tw',
    label: '繁體中文'
  },
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'ja',
    label: '日本語'
  },
  {
    value: 'ko',
    label: '한국어'
  }
]

// 语言包
const messages = {
  en: {
    ...elementEnLocale
  },
  zh: {
    ...elementZhLocale
  },
  zh_tw: {
    ...elementZhTwLocale
  },
  ja: {
    ...elementJaLocale
  },
  ko: {
    ...elementKoLocale
  }
}

export function getLanguage() {
  const chooseLanguage = localStorage.getItem('locale')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'ja'
}


// 重新加载国际化
export async function reloadMessage() {
  const dict = await loadDict()
  for (var lanObj of supportLans) {
    regMessage(lanObj.value, dict[lanObj.value])
  }
  return messages
}

// 注册语言包
export async function regMessage(lan, lanDict) {
  if (!lan || !lanDict) {
    return
  }
  // console.log('/lang/index.js,regMessage:', lan, ',lanDict:', lanDict)
  if (lan === 'zh') {
    messages['zh'] = {
      ...lanDict,
      ...elementZhLocale
    }
  } else if (lan === 'zh_tw') {
    messages['zh_tw'] = {
      ...lanDict,
      ...elementZhTwLocale
    }
  } else if (lan === 'en') {
    messages['en'] = {
      ...lanDict,
      ...elementEnLocale
    }
  } else if (lan === 'ja') {
    messages['ja'] = {
      ...lanDict,
      ...elementJaLocale
    }
  } else if (lan === 'ko') {
    messages['ko'] = {
      ...lanDict,
      ...elementKoLocale
    }
  }
}

// 网络加载国际化译文
export async function loadDict() {
  const url = `/official/store/lans`
  try {
    return ax.get(url).then(res => res.data)
  } catch (err) {
    console.log(err)
    return {}
  }
}

reloadMessage()

// 可以在初始化VueI18n实例时，通过 localStorage 来为 locale 对象赋值
const i18n = new VueI18n({
  // locale: localStorage.getItem('locale') || 'ja',
  locale: getLanguage() || 'ja',
  fallbackLocale: 'zh',
  formatFallbackMessages: true,
  messages,
  // 屏蔽console的warn
  silentTranslationWarn: true
})

// locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n