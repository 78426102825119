<template>
<div id="app">
  <keep-alive>
    <router-view />
  </keep-alive>
</div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {}
  },
  // watch: {
  //   $route: function (to) {
  //     console.log(to)
  //     if (to.name.indexOf('_mb') > 0 && !this._isMobile()) {
  //       this.$router.replace({ path: to.name.split('_mb')[0], query: to.query })
  //     } else if (to.name.indexOf('_mb') < 0 && this._isMobile()) {
  //       this.$router.replace({path: to.name + '_mb', query: to.query })
  //     }
  //   }
  // },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
